.root {
  .buttonN {
    .Button_root {
      max-width: 193px;
    }
  }

  .standardButton {
    max-width: 193px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nToOneButton {
    max-width: 193px;
    margin-top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .copyBlock {
    display: flex;
    width: 100%;
  }

  .buttonBlock {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .snackbars {
    & :global(.MuiSnackbarContent-root) {
      background-color: var(--primary-color);
      justify-content: center;
      border-radius: 16px;
      min-width: 0;

      :global(.MuiSnackbarContent-message) {
        color: var(--text-color);
        padding: 0;
      }
    }
  }
  .snackbar-warn {
    & :global(.MuiSnackbarContent-root) {
      background-color: var(--error-color);
      justify-content: center;
      border-radius: 16px;
      min-width: 270px;

      :global(.MuiSnackbarContent-message) {
        color: var(--text-color);
        padding: 0;
      }
    }
  }
}
