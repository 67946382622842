.root {
  background-color: var(--paper-color);
  border-right: 1px solid var(--line-color);
  width: 100%;

  & .title {
    margin-left: 5px;
    font-size: 12px;
    color: var(--label-text-color);
    font-weight: 500;
    line-height: 16px
  }
}

.list {
  height: calc(100vh - 292px);
  display: flex;
  //width: 100%;
}

.tab {
  width: 170px
}
