.message {
  display: flex;
  gap: 0.15rem;
  padding: 0 1rem;
}

.time {
  min-width: 190px;
}

.name {
  min-width: 130px;
}