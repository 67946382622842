.root {
  width: 100%;
  height: 250px;

  border: 2px dashed var(--outlined-button-color);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: var(--outlined-button-color);
  background-color: var(--input-color);

  transition: all .2s linear 0s;

  &:hover {
    color: var(--input-label-text-color);
    border: 2px dashed var(--input-label-text-color);
  }

  .description {
    font-weight: 900;
    font-size: 2rem;
    text-align: center;
  }
}


