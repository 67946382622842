.card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--list-element-border-color);
  border-radius: 8px;
  max-width: 550px;
  background-color: #272b34;
  box-shadow: 2px 2px 12px 1px rgba(140, 140, 140, 0.5);
  padding: 20px;
  margin: 0 auto;
}

.titleWrapperCard {
  font-weight: 900;
}

.cardTitle {
  margin: 0 auto;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
}

.headerCards {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 20px;
}

.cards {
  background-color: var(--paper-color);
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  color: #959cae;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 20px;
  margin: 10px auto;
}


.reading {
  font-size: 0.875rem;;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--green {
    color: #7fba7a !important;
  }

  &--yellow {
    color: #9a9a59 !important;
  }

  &--red {
    color: #ff754c !important;
  }

  &--gray {
    color: #373a43 !important;
  }
}

.reading strong {
  font-weight: bold;
  margin-right: 10px;
}

.logs {
  border: none;
  color: #959cae;
  background-color: #3a3e47;
  padding: 10px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 150px
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;

  &--error {
    margin-left: 10px;
  }
}


.arm {
  border: none;
  color: #959cae;
  background-color: #3a3e47;
  padding: 10px;
  text-align: center;
  width: auto;
  height: 60px;
  border-radius: 8px;
  margin-top: 10px;

}

.armText {
  font-size: 14px;
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  border-radius: 8px;
}

.reset-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#xyz-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}


.reading strong {
  font-weight: bold;
}

.reading span {
  font-weight: normal;
}

.buttonLog {
  width: max-content;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
  border: none !important;
  cursor: pointer;
  transition: all .2s linear 0s;
  position: relative;
  margin-left: 10px;
}

.icon {
  margin-right: 10px;
}
