@import "reset";
@import "./vars/common";
@import "fonts";
@import "utils";

* {
  font-family: "Inter", sans-serif;

  &::selection {
    color: var(--text-color);
    background-color: var(--primary-color);
  }
}

body, html {
  height: 100%;
}

body {
  margin: 0 !important;
  font-size: 0.875rem !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.integra_link {
  color: var(--primary-color);
  opacity: 1;
}

.sub {
  opacity: 0.5;
}

.pointer {
  transition: 0.5s;
}

.pointer:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.preWrap {
  white-space: pre-wrap;
}

/**::-webkit-scrollbar {*/
/*  width: 7px;*/
/*  height: 7px;*/
/*}*/
/**::-webkit-scrollbar-thumb {*/
/*  background-color: #D0D3D4;*/
/*  border-radius: 20px;*/
/*}*/


/* Let's get this party started */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  scroll-margin: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(150, 150, 150, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D0D3D4;
  -webkit-box-shadow: inset 0 0 6px rgba(150, 150, 150, 0.7);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #D0D3D4;
}


.red_pulse {
  animation-duration: 3s;
  animation-name: red_pulse_frames;
  animation-iteration-count: infinite;
}

@keyframes red_pulse_frames {
  0% {
    background-color: rgba(255, 0, 0, 0);
  }

  50% {
    background-color: rgba(255, 0, 0, 0.3);
  }

  100% {
    background-color: rgba(255, 0, 0, 0);
  }
}

@keyframes opacity_pulse_frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}



@for $i from 1 through 6 {
  h#{7 - $i} {
    font-family: "Mont", sans-serif;
  }
}

// Выключил react-error-overlay до исправления все ошибок
#webpack-dev-server-client-overlay {
  display: none;
}

#root {
  height: inherit;
}

.container {
  position: relative;
}

.dotted {
  background: radial-gradient(var(--input-color) 25%, transparent 20%) 0 0, radial-gradient(var(--input-color) 1%, transparent 1%) 10px 10px;
  background-size: 5px 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: unset;
  -webkit-text-fill-color: var(--text-color);
  -webkit-box-shadow: 0 0 0 1000px var(--input-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}

