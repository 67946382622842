.menu {
  z-index: 1500;
  padding: 20px;
  border-radius: 12px;
  width: 460px;
  overflow-y: auto;

  .title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 18px;
  }

  .reset {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--label-text-color);
    transition: all .2s linear 0s;
    cursor: pointer;

    &:hover {
      color: var(--text-color);
    }
  }
}
