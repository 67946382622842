.root {
  position: relative;
  margin: 1rem 0;

  :global {
    .data-type-label {
      font-weight: 600 !important;
      font-size: 0.875rem !important;
      color: var(--primary-color) !important;
      opacity: 1 !important;
    }

    .variable-value {
      font-size: 0.688rem;
    }

    .MuiAccordion-root {
      background-color: transparent;
      background-image: unset;
      box-shadow: unset;
      border: unset;


      &:before {
        display: none;
      }

      &.Mui-expanded {
        &:before {
          content: "";
          display: block !important;
          position: absolute;
          width: 1px;
          background-color: var(--label-text-color);
          opacity: 1 !important;
          left: 27px !important;
          top: 43px !important;
          height: calc(100% - 25px);
        }
      }

      .MuiAccordionSummary-root {
        flex-direction: row-reverse;


      }
    }

    .MuiAccordionSummary-expandIconWrapper {

      &.Mui-expanded {
        transform: rotate(90deg);
      }
    }

    .MuiAccordionSummary-content {
      width: 90%;
    }


    .Mui-expanded {
      margin: 0;
    }
  }

  .item {
    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: max-content;
    }
  }
}
