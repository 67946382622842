.root {
  border: 1px solid #32353F;
  padding: 10px;
  border-radius: 8px;

  .description {
    font-weight: 400 !important;
    line-height: 16px !important;
    min-height: 16px !important;
  }

}

