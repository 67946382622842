.scroller {
  overflow-x: auto;
}

.wrapper {
  position: relative;
}

.table {
  min-width: 1000px;
}

.head {
  position: sticky;
}

.body {
}

.row {
  display: grid;
  align-items: center;
  border-bottom: 1px solid #393d44;
}

.cell {
  display: flex;
  align-items: center;
}

.head {
  position: sticky;
  top: 0;
  margin-top: 0;
  background: #242731;

  .row {
    height: 60px;
  }

  .cell {
    padding: 20px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #b2b3bd;
  }
}

.body .row:hover {
  background: #242630;
}

.body .row > .cell {
  display: flex;
  align-items: center;
  padding: 8px;
  height: 46px;
  border-bottom: 0;

  font-family: 'Inter';
  font-style: normal;
  /* identical to box height, or 125% */

  /* White/100% */

  color: #ffffff;
}
