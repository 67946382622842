body[data-theme='dark'] {
  --flow-block-bottom-border: #32353f;
}

body[data-theme='light'] {
  --flow-block-bottom-border: #32353f;
}

.clicked {
  height: 100%;

  .clicked {
    border: 3px solid var(--primary-color);
  }
}

.snackbar {
  & :global(.MuiSnackbarContent-root) {
    background-color: var(--primary-color);
    justify-content: center;
    border-radius: 16px;
    min-width: 0;

    :global(.MuiSnackbarContent-message) {
      color: var(--text-color);
      padding: 0;
    }
  }
}

.root {
  position: relative;
  height: calc( 100% + 8px);
  margin: -4px -4px 0 -4px;

  & .toolbar {
    width: 63px;
    height: 304px;
    border: 1px solid var(--label-text-color);
    border-radius: 12px 0 0 12px;
    z-index: 250;
    background-color: var(--paper-color);
    position: absolute;
    left: -60px;
    top: 68px;
    display: flex;
    padding: 12px;


    &.active {
      border: 2px solid var(--primary-color);
    }

    &.inactive {
      border: 2px solid var(--flow-block-bottom-border);
    }

    &.isManyToOne {
      border-width: 3px;
    }

    &.isYellow {
      border: 2px solid #ffe2ac;
    }
  }

  .monitoring {
    height: 64px;
    min-width: 55px;
    border: 2px solid var(--dark-primary-color);
    background-color: var(--paper-color);
    right: calc(100% - 8px);
    border-radius: 12px 0 0 12px;
    position: absolute;
    padding: 10px;

    .monitoringWrapper {
      min-width: 45px;
      width: fit-content;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      & span {
        width: fit-content;
        align-items: center;
        flex: 1;
      }
    }

    &.active {
      border: 2px solid var(--primary-color);
    }

    &.inactive {
      border: 2px solid var(--flow-block-bottom-border);
    }

    &.isManyToOne {
      border-width: 3px;
    }

    &.isYellow {
      border: 2px solid #ffe2ac;
    }

    & .error {
      color: var(--error-color);
    }

    & .success {
      color: var(--success-color);
    }
  }

  .body {
    min-width: 380px;
    min-height: 344px;
    height: auto;
    width: calc(100% + 80px);

    background-color: var(--paper-color);

    border: 2px solid var(--dark-primary-color);
    border-radius: 12px;

    display: grid;
    grid-template-rows: minmax(80px, auto) minmax(116px, auto) minmax(64px, auto) minmax(64px, auto) minmax(
        64px,
                    auto
      );
    grid-template-columns: minmax(380px, 1fr);

    z-index: 500;

    &.active {
      border: 2px solid var(--primary-color);
    }

    &.inactive {
      border: 2px solid var(--flow-block-bottom-border);
    }

    &.isManyToOne {
      border-width: 3px;
    }

    &.isYellow {
      border: 2px solid #ffe2ac;
    }

    & .section_title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--flow-block-bottom-border);
      padding: 20px 10px 20px 20px;
      align-items: center;
      height: 100%;

      & .title_root {
        width: 100%;
        height: 100%;
      }
    }

    & .section {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--flow-block-bottom-border);
      padding: 20px;
      height: auto;
      align-items: center;
    }

    & .section_connect {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--flow-block-bottom-border);
      padding: 0px 20px 0px 20px;
      align-items: center;
    }

    & .bottom_section {
      padding: 0px 20px 0px 20px;
      display: flex;
      align-items: center;
    }

    & .status {
      &.error {
        color: var(--label-text-color);
      }

      &.success {
        color: var(--success-color);
      }
    }

    & .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      color: var(--text-color);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-height: 20px;

      &.inactive {
        color: var(--label-text-color);
      }
    }

    .description {
      font-weight: 400;
      line-height: 20px;
      min-height: 20px;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      color: var(--label-text-color);
      font-size: .875rem !important;
      overflow: auto;
      padding-right: 10px!important;
    }

    & p {
      color: var(--label-text-color);
      font-weight: 600;
      line-height: 1.25rem;
    }
  }
}

.sendMessageContainer {
  overflow-y: auto;
  height: 70vh;

  .subtitle {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 24px;
  }

  .editor {
    span {
      font-family: Consolas, 'Courier New', monospace;
    }
  }
}

.sendMessageTitle {
  font-weight: 900;
  font-size: 2.5rem;
}

.targetHandle {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: transparent;
  border-radius: unset;
  border: unset;
}

